import React from 'react'
import { Link } from 'gatsby'
import { FormattedMessage, useIntl } from 'react-intl'

import Layout from '../components/Layout'
import Content from '../components/Content'

const NotFoundPage = () => {
  const intl = useIntl()

  return (
    <Layout>
      <Content>
        <h1>
          <FormattedMessage id="404.title" />
        </h1>
        <FormattedMessage id="404.content" />{' '}
        <Link to={`/${intl.locale}`}>
          » <FormattedMessage id="404.home" />
        </Link>
      </Content>
    </Layout>
  )
}

export default NotFoundPage
